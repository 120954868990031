.navbar {
  // border-bottom: 2px solid #1c438a;
}

.navbar-brand {

  img {
    text-align: center;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: #4a5568;
  font-family: Barlow;
  font-weight: 600;
  font-size: 16px;
  font-style: normal;
  letter-spacing: 0px;
  margin: 0px 45px 20px 0px;
  padding: 0px 0px 5px 0px;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #4a5568;
  border-bottom: 3px solid #2d3748;
}

.navi-active {
  color: #4a5568;
  border-bottom: 3px solid #2d3748;
}

.navbar-light .navbar-nav .nav-link:focus {
  color: #4a5568;
}

.navbar-collapse {
  margin-top: 1rem;
}