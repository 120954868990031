.about-marg {
  margin-top: 10rem;
  margin-bottom: 12rem;
}

.sel-img {

  img {
    border-radius: 50%;
  }
}

.sel-bio {
  padding: 0px 45px 0px  15px;

  h1 {
    color: #333333;
    font-size: 34px;
    font-family: Barlow-Condensed;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: 0px;
    font-style: normal;
    margin: 22px 0px;
  }

  p {
    color: #333333;
    font-size: 17px;
    font-family: Barlow;
    font-style: normal;
    margin-bottom: 20px;
  }

  a {
    color: #f44336;
    text-decoration: none;
  }

  a:hover {
    color: #696969;
    text-decoration: none;
  }
}

.about-brands {
  margin-bottom: 5rem;

  h2 {
    color: #333333;
    font-size: 18px;
    font-family: Barlow-Condensed;
    margin-bottom: 2rem;
  }

  .brand-card {
    margin-bottom: 15px;

    .brand-img {

      img {
        width: 100%;
      }
    }

    .brand-desc {
      background-color: #EAEAEA;
      padding: 13px;

      h4 {
        color: #000000;
        font-family: "PT Sans", 'Helvetica Neue', Helvetica, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        margin-bottom: 0px;
      }

      p {
        color: #000000;
        font-family: "PT Sans", 'Helvetica Neue', Helvetica, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        margin-bottom: 0px;
      }
    }
  }
}