.portfolio-marg {
  margin-bottom: 2rem;
}

.portfolio-list-padd {
  margin-top: 5rem;
  margin-bottom: 1rem;

  button {
    color: #999;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #eee;
    font-family: "Marvel", 'Helvetica Neue', Helvetica, sans-serif;
    font-style: normal;
    font-size: 18px;
    padding: .5em 1.25em;
    margin-right: 10px;
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  button:hover {
    border: 1px solid #999;
  }

  button:focus {
    outline: none;
  }

  .active-button {
    border: 1px solid #999;
  }
}

.portfolio-items-padd {
  padding: 0px 2px;
  margin-bottom: 4px;
}

.portfolio-cards {
  cursor: pointer;
  margin: auto;
  position: relative;

  img {
    object-fit: cover;
    height: 300px;
    width: 100%;
  }
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #232323;
}

.portfolio-cards:hover .overlay {
  opacity: 0.9;
}

.text {
  color: #ffffff;
  font-size: 18px;
  font-family: "Marvel", 'Helvetica Neue', Helvetica, sans-serif;
  position: absolute;
  bottom: 0;
  font-weight: 500;
  margin-bottom: 2rem;
  padding: 20px;
  width: 100%;
}

.main-portfolio-padd {
  margin-top: 2rem;

  .previous-div {
    line-height: 34px;
    border-top: 1px solid #e7e6e6;
    border-bottom: 1px solid #e7e6e6;
    margin-bottom: 60px;
    text-align: right;

    .link-marg {
      margin-right: 15px;
    }

    a {
      color: #f44336;
      font-family: Barlow, "Times New Roman", Times, serif;
      font-weight: 400;
      letter-spacing: 0px;
      font-style: normal;
    }

    a:hover {
      color: #4a5568;
      text-decoration: none;
    }
  }

  h1 {
    color: #333333;
    font-size: 18px;
    font-family: "Barlow Condensed";
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0px;
    font-style: normal;
    margin-top: 0;
    margin-bottom: 28px;
  }

  .portfolio-image-div {
    margin-bottom: 2rem;
    
    img {
      width: 100%;
    }
  }

  .portfolio-text-marg {
    margin-bottom: 3rem;
  }

  .portfolio-text {
    
    p {
      font-family: Barlow, "Times New Roman", Times, serif;
      font-weight: 400;
      letter-spacing: 0px;
      font-style: normal;
      margin-top: 0;
      margin-bottom: 20px;
    }

    a {
      color: #f44336;
      font-family: Barlow, "Times New Roman", Times, serif;
      font-weight: 400;
      letter-spacing: 0px;
      font-style: normal;
    }

    a:hover {
      color: #4a5568;
      text-decoration: none;
    }

    span {
      font-family: Barlow, "Times New Roman", Times, serif;
      font-weight: 400;
      letter-spacing: 0px;
      font-style: normal;
      margin-top: 0;
      margin-bottom: 20px;
    }

    .quote-block {
      background-color: #f6f6f6;
      border-left: 4px solid #718096;
      color: #333333;
      margin: 40px;
      padding: 15px;
      text-align: center;

      p {
        margin: 0px;
        font-style: italic;
      }
    }
  }

  .portfolio-share-div {
    width: 100%;
    background-color: #f6f6f6;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px;
    margin-bottom: 60px;

    h4 {
      color: #333333;
      font-size: 13px;
      font-family: "Barlow Condensed";
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0px;
      font-style: normal;
      margin-bottom: 0;
    }
  }

  .portfolio-author {
    display: flex;
    margin-bottom: 1rem;

    h2 {
      color: #333333;
      font-size: 18px;
      font-family: "Barlow Condensed";
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0px;
      font-style: normal;
      flex-grow: 0;
      margin-right: 8px;
    }

    a {
      color: #f44336;
      font-size: 18px;
      font-family: "Barlow Condensed";
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0px;
      font-style: normal;
      flex-grow: 0;
      margin-right: 8px;
    }

    a:hover {
      color: #f44336;
      text-decoration: none;
    }

    .portfolio-author-lines {
      position: relative;
      height: 6px;
      border-bottom: 1px solid #e0dede;
      border-top: 1px solid #e0dede;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      margin-top: 0.7rem;
    }
  }

  .portfolio-author-img {
    margin-bottom: 3rem;

    img {
      width: 100px;
    }
  }

  .related-post-images {
    margin-bottom: 2rem;

    img {
      object-fit: cover;
      height: 200px;
      width: 100%;
    }
  }

  .portfolio-comment {
    margin-bottom: 1rem;

    p {
      color: #333333;
      font-size: 17px;
      font-family: Barlow;
      font-style: normal;
      margin-bottom: 20px;
    }

    input {
      height: 29px;
      background-color: #ffffff;
      font-size: 15px;
      color: #aaa9a9;
      border: 1px solid #d2d2d2;
      padding: 8px 15px;
      width: 100%;
    }

    input:focus {
      outline: none;
      border: 1px solid #999999;
    }

    textarea {
      background-color: #ffffff;
      font-size: 15px;
      color: #aaa9a9;
      border: 1px solid #d2d2d2;
      padding: 8px 15px;
      width: 100%;
    }

    textarea:focus {
      outline: none;
      border: 1px solid #999999;
    }

    button {
      background-color: #65bc7b;
      color: #ffffff;
      padding: 13px 29px;
      line-height: 17px;
      font-size: 14px;
      font-family: PT Sans;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0px;
      border-width: 0px;
      border-style: solid;
      border-radius: 0px;
      text-transform: uppercase;
      -webkit-transition: all .2s;
      transition: all .2s;
    }

    button:focus {
      outline: none;
    }
  }

  .portfolio-comment-check {
    margin-bottom: 1rem;

    input {
      margin-right: 10px;
    }

    span {
      color: #333333;
      font-size: 17px;
      font-family: Barlow;
      font-style: normal;
    }
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 8px;
  border: 3px solid #333333;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #333333 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
