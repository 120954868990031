.footer-padd {
  background-color: #ffffff;
  color: #4a5568;
  padding: 20px 0px;

  span {
    color: #4a5568;
    font-family: Barlow;
    font-size: 14px;
  }
}