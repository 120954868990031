body {
  background-image: url('../img/bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
}

.home-text-style {
  color: #fff;
  margin-top: 10rem;
  height: 100vh;
}

.carousel {
  margin-bottom: 2rem;
}

.carousel-inner {
  height: 772px;

  img {
    height: 772px;
    object-fit: cover;
  }
}

.carousel-indicators {
  display: none;
}

.carousel-item {

  img {
    width: 100%;
  }
}

.carousel-control-next {
  display: none;
}

.carousel-control-prev {
  display: none;
}