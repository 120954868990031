.contact-marg-top {
  margin-top: 5rem;
  margin-bottom: 10rem;
}

.contact-marg  {
  margin-bottom: 2rem;

  p {
    color: #333333;
    font-size: 17px;
    font-family: Barlow;
    font-style: normal;
    margin-bottom: 20px;
  }

  input {
    height: 29px;
    background-color: #ffffff;
    font-size: 15px;
    color: #aaa9a9;
    border: 1px solid #d2d2d2;
    padding: 8px 15px;
    width: 100%;
  }

  input:focus {
    outline: none;
    border: 1px solid #999999;
  }

  textarea {
    background-color: #ffffff;
    font-size: 15px;
    color: #aaa9a9;
    border: 1px solid #d2d2d2;
    padding: 8px 15px;
    width: 100%;
  }

  textarea:focus {
    outline: none;
    border: 1px solid #999999;
  }

  button {
    background-color: #65bc7b;
    color: #ffffff;
    padding: 13px 29px;
    line-height: 17px;
    font-size: 14px;
    font-family: PT Sans;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    border-width: 0px;
    border-style: solid;
    border-radius: 0px;
    text-transform: uppercase;
    -webkit-transition: all .2s;
    transition: all .2s;
  }

  button:focus {
    outline: none;
  }
}